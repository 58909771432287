import TExamsBoxes from "./TExamsBoxes/TExamsBoxes";
import TExamsTable from "./TExamsTable/TExamsTable"

export default function Exams() {


	return (

		<>
			<TExamsBoxes />
			<TExamsTable />

		</>
	);
}

import CoursesBoxes from "../../Boxes/CoursesBoxes/CoursesBoxes";
import CoursesTable from "../../Components/Tables/CoursesTable/CoursesTable";


export default function AssistantCourses() {

  return (

    <>
      <CoursesBoxes />
      <CoursesTable />
    </>

  );
}

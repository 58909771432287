import React from 'react';
import icon from '../../Assets/sanadSVG/students.svg';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { MainContext } from '../../Context/MainContext';
import { ApisContext } from '../../Context/ApisContext';
import { ReactSVG } from 'react-svg';
import teachers from '../../Assets/sanadSVG/teachers.svg';
import Post from '../../Components/Skeletons/Post';
export default function AssistantsBoxs() {
	let [t] = useTranslation();
	const { Toggler, setToggler } = useContext(MainContext);
	const { tens, fetchTutorKeyForTa } =
		useContext(ApisContext);


	return (
		<div className="w-full flex flex-col xl:flex-row gap-x-6 gap-y-4 justify-between">

			{fetchTutorKeyForTa.isFetched ? <div

				onClick={() => setToggler({ ...Toggler, addAssistant: true })}
				className="box shadow-md  xl:shadow-none w-full xl:w-1/3 flex items-center justify-center gap-x-3 cursor-pointer   bg-white rounded-2xl p-4 2xl:p-3  2xl:gap-x-4"
			>
				<div className=" flex justify-center items-center">
					<ReactSVG src={icon} />
				</div>
				<div className=" flex flex-col justify-center items-center 2xl:items-start gap-y-2">
					<p className="text-xl sm:text-2xl   text-mainColor font-extrabold">
						{t('homepage.add')} {t('homepage.assistant')}
					</p>
				</div>
			</div> : <div className="w-full p-3 xl:w-1/3 bg-white rounded-2xl flex items-center justify-center">
				<Post />
			</div>}

			{fetchTutorKeyForTa.isFetched ?
				<div className="box shadow-md xl:shadow-none w-full xl:w-1/3 flex items-center justify-center gap-x-3   bg-gradient-to-r from-[#023E8AB2] to-[#2684FFCC]  rounded-2xl p-4  2xl:gap-x-4">
					<div className=" flex justify-center items-center">
						{/* <span className="w-16 h-16 rounded-full flex justify-center items-center p-2">
						<img className="w-8" src={icon1} alt="" />
					</span> */}
						<ReactSVG src={icon} />
					</div>
					<div className=" flex flex-col justify-center items-center 2xl:items-start gap-y-2">
						<p className="text-xl  text-white">{t('homepage.assistantNum')}</p>
						<p className=" text-xl  text-white font-extrabold">
							{/* {userDetails.tutorData.totalTAs} {t("homepage.assistants")} */}

							{fetchTutorKeyForTa.data?.tutorData?.totalTAs > 0
								? tens.includes(fetchTutorKeyForTa.data?.tutorData?.totalTAs)
									? `${fetchTutorKeyForTa.data?.tutorData?.totalTAs} ${t('homepage.assistants')}`
									: `${fetchTutorKeyForTa.data?.tutorData?.totalTAs} ${t('homepage.assistant')}`
								: t('homepage.nothing')}

							{ }
						</p>
					</div>
				</div>
				:

				<div className="w-full p-3 xl:w-1/3 bg-white rounded-2xl flex items-center justify-center">
					<Post />
				</div>
			}

			{fetchTutorKeyForTa.isFetched ? (
				<div className="box w-full xl:w-1/3 bg-white rounded-2xl flex p-4 gap-x-4 sm:gap-x-4 justify-center">
					<>
						<ReactSVG src={teachers} />

						<div className="title text-nowrap">
							<p className=" text-textGray text-xl font-bold">
								{t('homepage.centersNum')}
							</p>
							<p className="text-mainColor text-xl font-bold">
								{fetchTutorKeyForTa.data?.tutorData?.totalCenters > 0
									? tens.includes(fetchTutorKeyForTa.data?.tutorData?.totalCenters)
										? `${fetchTutorKeyForTa.data?.tutorData?.totalCenters} ${t(
											'homepage.centerss'
										)}`
										: `${fetchTutorKeyForTa.data?.tutorData?.totalCenters} ${t(
											'homepage.centers'
										)}`
									: t('homepage.nothing')}
							</p>
						</div>
					</>
				</div>
			) : (
				<div className="w-full p-3 xl:w-1/3 bg-white rounded-2xl flex items-center justify-center">
					<Post />
				</div>
			)}
		</div>
	);
}

import React, { useContext } from "react";
import gallary from "../../Assets/sanadSVG/Subtract.svg";
import { useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Context/MainContext";
import ImageUploader from "./ImageUploader";
import * as Yup from 'yup'

export default function Form4({ back, next, data }) {
    const { AuthLoading, profileImage } = useContext(MainContext)
    console.log(profileImage)
    let [t] = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [fileName, setFileName] = useState("");
    const handleFileSelect = (files) => {
        setSelectedFiles([...files]);
        setFileName(files[0]?.name); // Assuming only a single file is selected
        setFileSelected(true);
    };

    // tutors/
    // Sdk4RSl_



    const stepFourValidation = Yup.object({

        profileImage: Yup.string()
            .required(t("Errors.profileImage"))


    });


    const handleSubmit = (values) => {
        next(values, true, false);
    };



    return (
        <>
            <Formik initialValues={data} onSubmit={handleSubmit} validationSchema={stepFourValidation} >
                {(formikProps) => {
                    console.log(formikProps.values)
                    // formikProps.setFieldValue("email", storedEmail)
                    return (
                        <Form className="w-full">
                            <div className="profile-pic flex flex-col w-full justify-center items-center my-8  ">
                                <span className="text-mainColor w-full text-start font-semibold text-size_12  relative">
                                    {t("register.profileImage")}
                                </span>


                                <ImageUploader formikProps={formikProps} />

                            </div>

                            {formikProps.errors.profileImage && formikProps.touched.profileImage && (
                                <div className="flex items-center gap-x-2  mb-3">
                                    <span className="w-[5px] h-[5px] bg-[#E4363DE8]"></span>
                                    <p className="text-[#E4363DE8] text-xs sm:text-size_12  ">
                                        {formikProps.errors.profileImage}
                                    </p>
                                </div>
                            )}

                            <div className="w-full flex  gap-x-2 ">
                                <button
                                    // disabled={!(formikProps.isValid)}
                                    type="submit"
                                    className="text-white flex justify-center text-sm  text-nowrap items-center bg-mainColor hover:bg-secondMainColor font-bold rounded-2xl  focus:outline-none w-1/2 py-3 "
                                >
                                    {AuthLoading.register ? (
                                        <div
                                            className={`w-6 h-6 border-t-4 border-white border-solid rounded-full animate-spin block`}
                                        ></div>
                                    ) : (
                                        <>{t("register.newAccount")}</>
                                    )}
                                </button>
                                <button
                                    onClick={() => {
                                        back(formikProps.values);
                                    }}
                                    type="button"
                                    className="text-white flex justify-center text-sm items-center bg-mainColor hover:bg-secondMainColor font-bold rounded-2xl   focus:outline-none w-1/2 py-3 "
                                >
                                    {t("register.prev")}
                                </button>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>
        </>
    );
}
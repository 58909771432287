import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
} from 'react';

import { useTranslation } from 'react-i18next';
// import icon8 from "../../../../Assets/textareaIcons/Polygon 2.png"
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/react';
import { useFormik } from 'formik';
import { MainContext } from '../../../Context/MainContext';
import x from '../../../Assets/sanadSVG/Multiply.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import examIcon from '../../../Assets/sanadSVG/online.svg';
import downarrow from '../../../Assets/sanadSVG/downArrow.svg';
import filterIcon from '../../../Assets/sanadSVG/filterIcon.svg';
import { ReactSVG } from 'react-svg';
import { ApisContext } from '../../../Context/ApisContext';
import * as Yup from 'yup';
import MultieSelect from './MultieSelect';
import marker from "../../../Assets/sanadSVG/checked.svg"
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function OnlineExamQBank() {
    const currentDay = dayjs();
    let [t] = useTranslation();
    const { Toggler, setToggler } = useContext(MainContext);
    const { tens, isValidInput, fetchTopics, fetchSpcificSessionsToCourse, qGenerated, addOnlineELoading, setqGenerated,
        addOnlineAutoGeneratedExam, } = useContext(ApisContext);



    const [selectedSessions, setselectedSessions] = useState([])
    const [selectedTopics, setselectedTopics] = useState([])
    const [startTime, setstartTime] = useState(dayjs());
    const [endTime, setendTime] = useState(dayjs().add(2, 'hour'));



    const formik = useFormik({
        initialValues: {
            title: "",
            questions: qGenerated,
            startTime: startTime?.toISOString(),
            endTime: endTime?.toISOString(),
            topics: selectedTopics,
            sessions: selectedSessions,
            durationInMinutes: "",
            type: "online",
            expectedMark: "",
        }
        ,
        validationSchema: Yup.object({

            title: Yup.string().required(t("homepage.requiredField")),
            durationInMinutes: Yup.number().required(t("homepage.requiredField")).positive().min(5, t("homepage.durationMin")).max(10800, t("homepage.durationMin")),
            expectedMark: Yup.number().required(t("homepage.requiredField")).positive(),
            startTime: Yup.date()
                .required(t("homepage.requiredField"))
                .test('is-after-now', t("homepage.startTime"), function (value) {
                    return dayjs(value).isAfter(dayjs().add(4, 'minute'));
                }),
            endTime: Yup.date()
                .required(t("homepage.requiredField")).test('is-after-startTime', t("homepage.endTime"), function (value) {
                    const { startTime } = this.parent; // Access startTime from parent object
                    // Ensure endTime is after startTime
                    return dayjs(value).isAfter(dayjs(startTime));
                }),


            questions: Yup.array()
                .of(Yup.string().required(''))
                .min(1, 'At least one topic is required')
                .required('topics is required'),
            sessions: Yup.array()
                .of(Yup.string().required(t("homepage.requiredField")))
                .min(1, t("homepage.oneItemAtleast"))
                .required(t("homepage.requiredField")),

            topics: Yup.array()
                .of(Yup.string().required(t("homepage.requiredField")))
                .min(1, t("homepage.oneItemAtleast"))
                .required(t("homepage.requiredField")),

        }),
        onSubmit: async (values, { resetForm }) => {



            addOnlineAutoGeneratedExam(values, resetForm, setstartTime, setendTime, currentDay)


        }
    })







    function close() {
        setToggler({ ...Toggler, onlineQBank: false });
        formik.resetForm()
        setselectedTopics([])
        setselectedSessions([])
    }


    useEffect(() => {
        if (!Toggler.onlineQBank) {
            formik.resetForm()
            setselectedTopics([])
            setselectedSessions([])

        }
    }, [Toggler.onlineQBank])


    useEffect(() => {
        const x = qGenerated?.map((item) => item?._id)
        formik.setFieldValue("questions", x)
    }, [Toggler.autoQusetions, qGenerated, setqGenerated])



    return (
        <>
            <Dialog
                open={Toggler.onlineQBank}
                as="div"
                className="relative z-30 focus:outline-none"
                onClose={close}
            >
                <div className="fixed inset-0 z-40 w-screen overflow-y-auto scrollbar-thin bg-black/10">
                    <div className="flex min-h-full b items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full md:w-[80%] lg:w-[70%] rounded-xl bg-bg_mainLayout  bg-HomePageBgImage p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <button
                                className="flex items-center justify-center p-2 bg-white rounded-full"
                                onClick={() => setToggler({ ...Toggler, onlineQBank: false })}
                            >
                                <ReactSVG src={x} />
                            </button>
                            <DialogTitle
                                as="h3"
                                className="text-base/7 font-medium text-mainColor"
                            >
                                <div className="flex flex-col items-center justify-between rounded-t gap-y-1">
                                    <ReactSVG src={examIcon} />
                                    <h3 className="text-xl sm:text-2xl font-black text-[#023E8A]">
                                        {t("exam.addExam")}
                                    </h3>
                                </div>
                            </DialogTitle>
                            <form onSubmit={formik.handleSubmit} className="flex flex-col  gap-y-2 relative 2xl:gap-y-3 my-2">
                                <div className="row1 flex flex-col md:flex-row gap-y-3 md:items-center md:gap-x-2 lg:gap-x-4">
                                    <div className="examName flex flex-col gap-y-2 md:w-3/5 ">
                                        <label
                                            className="text-sm text-secondMainColor font-semibold"
                                            htmlFor="title"
                                        >
                                            {t('exam.examName')}
                                        </label>
                                        {formik.errors.title && formik.touched.title && <p className='text-err text-xs  '>{formik.errors.title}</p>}

                                        <input
                                            className={`bg-white rounded-xl px-4 py-3  shadow-sm text-xs xl:text-sm text-mainColor font-bold placeholder:text-textGray ${isValidInput(formik.errors?.title, formik.touched?.title)}`}
                                            placeholder={t('exam.examName')}
                                            type="text"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            name="title"
                                            id="title"
                                            onBlur={formik.handleBlur}


                                        />
                                    </div>

                                    {/* <div className="examType flex flex-col gap-y-2 md:w-2/5 ">
                                        <label
                                            className="text-sm text-secondMainColor font-semibold"
                                            htmlFor="type"
                                        >
                                            {t('exam.exmaType')}
                                        </label>
                                        <input
                                            className="bg-white rounded-xl px-4 py-3 border-input_border border-[1px] shadow-sm text-xs xl:text-sm text-mainColor font-bold placeholder:text-textGray "
                                            placeholder={t('exam.finalGrade')}
                                            type="number"
                                            name='type'
                                            id='type'
                                            value={formik.values.type}
                                            // onChange={formik.handleChange}
                                            readOnly

                                        />
                                    </div> */}

                                    <div className="grade flex flex-col gap-y-2 md:w-2/5">
                                        <label
                                            className="text-sm md:text-xs lg:text-sm text-secondMainColor font-semibold"
                                            htmlFor="expectedMark"
                                        >
                                            {t('exam.finalGrade')}
                                        </label>
                                        {formik.errors.expectedMark && formik.touched.expectedMark && <p className='text-err text-xs  '>{formik.errors.expectedMark}</p>}

                                        <input
                                            className={`bg-white rounded-xl px-4 py-3 ${isValidInput(formik.errors?.expectedMark, formik.touched?.expectedMark)} shadow-sm text-xs xl:text-sm text-mainColor font-bold placeholder:text-textGray `}
                                            placeholder={t('exam.finalGrade')}
                                            type="number"
                                            name='expectedMark'
                                            id='expectedMark'
                                            value={formik.values.expectedMark}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>

                                <div className="row2 flex flex-col md:flex-row gap-y-3 md:items-center md:gap-x-2 lg:gap-x-4">
                                    <div className="startTime flex flex-col gap-y-2 md:w-2/5">
                                        <label
                                            htmlFor="startTime"
                                            className="text-sm md:text-xs lg:text-sm text-secondMainColor font-semibold"
                                        >
                                            {t('exam.startTime')}
                                        </label>
                                        {formik.errors.startTime && <p className='text-err text-xs  '>{formik.errors.startTime}</p>}
                                        <div id="revDate" className="w-full">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    className="w-full "
                                                    value={startTime}
                                                    // disabled={isError && isError.value === 'start'}
                                                    format="YYYY-MM-DD :hh:mm"
                                                    onChange={(e) => {
                                                        // setvalues({ ...values, startTime: e })
                                                        setstartTime(e)
                                                        formik.setFieldValue("startTime", e.toISOString())

                                                    }
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="endTime flex flex-col gap-y-2 md:w-2/5">
                                        <label
                                            htmlFor="endTime"
                                            className="text-sm md:text-xs lg:text-sm text-secondMainColor font-semibold"
                                        >
                                            {t('exam.endTime')}
                                        </label>
                                        {formik.errors.endTime && <p className='text-err text-xs  '>{formik.errors.endTime}</p>}

                                        <div id="revDate" className="w-full">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    className="w-full"
                                                    value={endTime}
                                                    // disabled={isError && isError.value === 'start'}
                                                    format="YYYY-MM-DD :hh:mm"
                                                    onChange={(e) => {
                                                        setendTime(e)
                                                        formik.setFieldValue("endTime", e.toISOString())

                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="minuties flex flex-col gap-y-2 md:w-1/5 ">
                                        <label
                                            className="text-sm text-secondMainColor font-semibold"
                                            htmlFor="durationInMinutes"
                                        >
                                            {t('exam.timeMin')}
                                        </label>
                                        {formik.errors.durationInMinutes && formik.touched.durationInMinutes && <p className='text-err text-xs  '>{formik.errors.durationInMinutes}</p>}
                                        <input
                                            className={`bg-white rounded-xl px-4 py-3  shadow-sm text-xs xl:text-sm text-mainColor font-bold placeholder:text-textGray ${isValidInput(formik.errors?.durationInMinutes, formik.touched?.durationInMinutes)}`}
                                            placeholder={t('exam.timeMin')}
                                            type="number"
                                            value={formik.values.durationInMinutes}
                                            onChange={formik.handleChange}
                                            name='durationInMinutes'
                                            id='durationInMinutes'
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>






                                <div className="row3 flex flex-col md:flex-row gap-y-3 md:items-center md:gap-x-2 lg:gap-x-4">
                                    <div className="topics flex flex-col gap-y-2 md:w-1/2">
                                        <label
                                            className="text-sm md:text-xs lg:text-sm text-secondMainColor font-semibold"
                                            htmlFor="topics"
                                        >
                                            {t('PopUps.chooseTopic')} -
                                            <span className="text-xs  md:text-2xs lg:text-xs">
                                                {t('PopUps.chooseMultipleTopics')}
                                            </span>
                                        </label>
                                        {formik.errors.topics && formik.touched.topics && <p className='text-err text-xs  '>{formik.errors.topics}</p>}
                                        <MultieSelect
                                            items={fetchTopics.data}
                                            selectedItems={selectedTopics}
                                            setSelectedItems={setselectedTopics}
                                            isValid={isValidInput(formik?.errors?.topics, formik.touched?.topics)}
                                            type={"topics"}
                                            handleBlur={formik.handleBlur}
                                            formik={formik}
                                        />
                                    </div>

                                    <div className="sessions flex flex-col gap-y-2 md:w-1/2">
                                        <label
                                            className="text-sm md:text-xs lg:text-sm text-secondMainColor font-semibold"
                                            htmlFor="sessions"
                                        >
                                            {t('exam.sessions')} -
                                            <span className="text-xs md:text-2xs lg:text-xs">
                                                {t('exam.multi')}
                                            </span>
                                        </label>
                                        {formik.errors.sessions && formik.touched.sessions && <p className='text-err text-xs  '>{formik.errors.sessions}</p>}

                                        <Listbox
                                            // value={selectedCenter}
                                            onChange={(e) => {
                                                // setselectedCenter(e)
                                                // sessionStorage.clear()
                                                // sessionStorage.setItem("centerid", JSON.stringify(e))
                                                let x = e?.map(item => {
                                                    return item?._id
                                                })
                                                setselectedSessions(x)
                                                formik.setFieldValue("sessions", x)
                                            }}
                                            multiple
                                        >
                                            {({ open }) => (
                                                <div className="relative ">

                                                    <ListboxButton
                                                        onBlur={formik.handleBlur}
                                                        id='sessions'
                                                        className={`font-semibold w-full px-4 py-3 sm:gap-x-3 items-center justify-between   text-mainColor ${isValidInput(formik.errors?.sessions, formik.touched?.sessions)}        sm:py-3  text-sm
                                                                        flex cursor-pointer rounded-xl bg-white  text-left focus:outline-none  sm:text-sm `}
                                                    >
                                                        <div className="flex items-center ps-2 sm:p-0 gap-x-2">
                                                            <ReactSVG src={filterIcon} />


                                                            <span
                                                                className={`block truncate text-sm text-mainColor`}
                                                            >
                                                                {selectedSessions?.length > 0 ? tens.includes(selectedSessions?.length) ? `${selectedSessions?.length} ${t("exam.choises")}` : `${selectedSessions?.length} ${t("exam.oneChoise")}` : t("exam.choise")}
                                                                {/*  : t("exam.wait") */}
                                                            </span>
                                                        </div>

                                                        <ReactSVG src={downarrow} />
                                                    </ListboxButton>

                                                    <ListboxOptions
                                                        onBlur={formik.handleBlur}
                                                        className="absolute  mt-12 max-h-40 z-10
                                     w-full overflow-y-scroll rounded-md bg-white py-1 text-base scrollbar-thin shadow  focus:outline-none sm:text-sm "
                                                    >
                                                        {fetchSpcificSessionsToCourse.data?.length > 0 ? fetchSpcificSessionsToCourse.data?.map((person, personIdx) => (

                                                            <ListboxOption key={personIdx} value={person} className={({ active, selected }) =>
                                                                `cursor-default select-none relative py-2 flex items-center justify-between px-4   ${selected ? 'font-medium' : 'font-normal'} cursor-pointer`
                                                            }  >

                                                                {({ selected }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block text-xs truncate break-all ${selected ? 'font-medium' : 'font-normal'
                                                                                }`}
                                                                        >

                                                                            {/* {person?.name?.split(" ").length > 5 ? `${person?.name.slice(0, 5).join(" ")}...` : person?.name} */}
                                                                            {person?.name}
                                                                        </span>
                                                                        <div className={`w-1 h-1 sm:w-2 sm:h-2 border-mainColor border-2 p-2 flex items-center justify-center ${selected ? "bg-mainColor" : "text-textColor__2"}   rounded-md`}>
                                                                            {selected &&
                                                                                <ReactSVG src={marker} />
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </ListboxOption>
                                                        ))
                                                            : <span className='text-center w-full p-2 text-xs text-textGray font-bold '>{t("homepage.nothing")}</span>
                                                        }
                                                    </ListboxOptions>
                                                </div>
                                            )}
                                        </Listbox>
                                    </div>



                                </div>

                                <div className="formBtns mt-4 flex flex-col sm:flex-row gap-x-3 justify-center items-center">
                                    <button
                                        disabled={!(formik.isValid && formik.dirty && qGenerated.length > 0)}
                                        type="submit"
                                        // onClick={() => sethandleAddExam(prev => !prev)}
                                        className={`text-white  ${!(formik.isValid && formik.dirty && qGenerated.length > 0)
                                            ? "bg-secondMainColor"
                                            : "bg-mainColor"
                                            }  text-center rounded-2xl px-10 py-2 sm:py-3 w-full md:w-1/2 text-lg`}
                                    >


                                        {addOnlineELoading ? <div className={`w-6  ms-[40%] h-6 border-t-4 text-center border-white border-solid rounded-full animate-spin block`}
                                        ></div> : t('homepage.adding')}

                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setToggler((prev) => {
                                                return { ...prev, onlineQBank: false };
                                            });
                                        }}
                                        className="bg-transparent text-mainColor rounded-2xl px-10 py-2 sm:py-3 w-full md:w-1/2 text-lg"
                                    >
                                        {t('homepage.back')}
                                    </button>
                                </div>
                            </form>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

import React, {
    useContext,

} from 'react';

import { useTranslation } from 'react-i18next';
// import icon8 from "../../../../Assets/textareaIcons/Polygon 2.png"
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { MainContext } from '../../../Context/MainContext';
import x from '../../../Assets/sanadSVG/Multiply.svg';
import addExam from '../../../Assets/sanadSVG/feedback.svg';
import { ReactSVG } from 'react-svg';
export default function QuestionDetails() {
    let [t] = useTranslation();
    const { Toggler, setToggler, selectedQuestion } = useContext(MainContext);
    function close() {
        setToggler({ ...Toggler, qestion: false });
    }

    return (
        <>
            <Dialog
                open={Toggler.qestion}
                as="div"
                className="relative z-30 focus:outline-none"
                onClose={close}
            >
                <div className="fixed inset-0 z-40 w-screen overflow-y-auto scrollbar-thin bg-black/10">
                    <div className="flex min-h-full b items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-[500px] sm:w-3/4 lg:w-2/4 rounded-xl bg-bg_mainLayout  bg-HomePageBgImage p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <button
                                className="flex items-center justify-center p-2 bg-white rounded-full"
                                onClick={() => setToggler({ ...Toggler, qestion: false })}
                            >
                                <ReactSVG src={x} />
                            </button>
                            <DialogTitle
                                as="h3"
                                className="text-base/7 font-medium text-mainColor"
                            >
                                <div className="flex flex-col items-center justify-between rounded-t gap-y-1">
                                    <ReactSVG src={addExam} />
                                    <h3 className="text-xl sm:text-2xl font-black text-[#023E8A]">
                                        {t('exam.qDetails')}
                                    </h3>
                                    {/* <p className="text-center text-sm  sm:text-base">
                                        {t('exam.pConfirmation')}
                                    </p> */}
                                </div>
                            </DialogTitle>

                            <h1 className="qhead   break-all text-center  rounded-xl my-3 w-full sm:text-xl font-bold text-textColor__2">
                                {selectedQuestion?.title}
                            </h1>


                            <div className="container ">
                                {selectedQuestion?.options?.map((option, i) => {
                                    return <div key={i} className={`ans px-3 mb-2 sm:px-6 py-2 rounded-lg ${option?.isCorrect ? "bg-bg_green text-text_green" : "bg-white "} indent-5 break-all  sm:leading-5`}>
                                        {option?.option}
                                    </div>
                                })}
                            </div>

                            {/* <div className="formBtns mt-4 flex flex-col sm:flex-row gap-x-3 justify-center items-center">
                                <button
                                    // disabled={!(formik.isValid && formik.dirty)}
                                    type="submit"
                                    className={`text-white bg-mainColor  text-center rounded-2xl px-10 py-2 sm:py-3 w-full md:w-1/2 text-lg`}
                                >
                                    {t('homepage.adding')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setToggler((prev) => {
                                            return { ...prev, qestion: false };
                                        });
                                    }}
                                    className="bg-transparent text-mainColor rounded-2xl px-10 py-2 sm:py-3 w-full md:w-1/2 text-lg"
                                >
                                    {t('homepage.back')}
                                </button>
                            </div> */}
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
